<template>
  <div
    :style="getElementStyle"
    v-if="data.properties.filed_content != 'Hide'"
  >
    <el-row align="middle">
      <div>
        <el-col
          :span="showLabel && !isDefalutPosq ? 8 : data.description ? 8 : 24" style="white-space: nowrap;"
          v-if="
            !data.properties.hideLabel &&
            data.properties.filed_content != 'Hide' ||  hasLabel 
          "
        >
          <!-- {{ data.properties }} -->
          {{ label|| data.label }}
          <span
            v-if="data.validations.required && hasLabel && !isView "
            style="color: red; font-weight: bold"
          >
            *
          </span>
          <span v-if="data.description">
            <i
              class="el-icon-info"
              :title="data.description"
            />
          </span>
        </el-col>
       
        <!-- <el-col :span="4">
          <span v-if="data.description">
            <i
              class="el-icon-info"
              :title="data.description"
            />
          </span>
        </el-col> -->
      </div>
      <!-- {{ data.source }} -->
      <el-col :span="showLabel && !isDefalutPosq ? 12 : 24">
        <div
          class="icon-block"
          v-if="data.properties.filed_content != 'Hide'"
          :style="getStyle"
          :class="{ 'highlighted-border': highlight }"
        >
          <div
            class="icon avatar-uploader"
            style="width:100%"
            @click="openSignature"
          >
          <img
              :src="form[data.key] || data.default_value"
              v-if="form[data.key] && !data.source"
              class="sign_img"
              alt="signature"
            />
            <img
              :src="data.source || data.default_value"
              v-else-if="data.source || data.default_value"
              class="sign_img"
              alt="signature"
            />
            <div v-if="
                !data.source &&
                !data.default_value &&
                !this.form[this.data.key] &&
                data.properties.filed_content != 'Hide' 
              "
              style="margin-top:15px"
              >
              signature
              <i class="el-icon-edit" />
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="showLabel && !isDefalutPosq ? 12 : 24">
        <dialog-component
          :visible="signatureDialogVisible"
          @before-close="signatureDialogVisible=false"
          :title="'Signature'"
        >
          <SignaturePad :field="data"></SignaturePad>
          <span slot="footer" class="dialog-footer">
       
            <el-button class="capitalize" type="primary" :disabled="disableButton" @click="saveSign"
              >Save Signature</el-button
            >
          </span>
        </dialog-component>
      </el-col>
    </el-row>

    <!-- {{   this.form[this.data.key] }} -->
    <!-- {{ data }} -->
    <!-- {{ form }} -->
    <!-- {{   this.form[data.key]}} -->
  </div>
</template>

<script>
import SignaturePad from "../formComponents/SignaturePad";
import userPermissionsHelper from "@/mixins/userPermissionsHelper";
import { bus } from "../../../main";
export default {
  mixins: [userPermissionsHelper],
  name: "templates-formComponentsExecute-ESignatureExecute",
  components: {
    SignaturePad
  },
  props: ["data","label", "form", "isView", "hasLabel","highlight","fromEntityViews"],
  computed: {
    getStyle() {
      return `height:${this.data.height - 30}px`;
    },
    // tharuni Start
    getElementStyle() {
      let borderStyle = "";
      if (this.data.styles) {
        let type = this.data.styles.border_type
          ? this.data.styles.border_type
          : "solid";
        let size = this.data.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0px";
        let color = this.data.styles.border_color
          ? this.data.styles.border_color
          : "";
        let font_size = this.data.styles.font.font_size
          ? this.data.styles.font.font_size
          : "";
        let bold =
          this.data.styles.font.style == 1 || this.data.styles.font.style == 3
            ? "bold"
            : "";
        let italic =
          this.data.styles.font.style == 2 || this.data.styles.font.style == 3
            ? "italic"
            : "";

        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += "font-size:" + font_size + "px;";
        if (bold) {
          borderStyle += "font-weight:" + bold + ";";
        }
        if (italic) {
          borderStyle += "font-style:" + italic + ";";
        }
        borderStyle += this.data.styles.background
          ? `background: ${this.data.styles.background}`
          : "";
        borderStyle += ";";
        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";
        borderStyle += ";";
      }
      return borderStyle;
    },
    isDefalutPosq() {
      if (this.data.styles && this.data.styles.labelStyle == "right") {
        return false;
      } else {
        return true;
      }
    }
    // tharuni End
  },
  data() {
    return {
      validations: [],
      signatureDialogVisible: false,
      isDefalutPos: true,
      showLabel: true,
      disableButton: true,
    };
  },
  // tharuni start
  mounted() {
    bus.$on('buttonClickedonload', (value) => {
      console.log("button out",value);
      this.disableButton = true;
    });
  
    bus.$on('buttonClicked', (value) => {
      console.log("button out",value);
      this.disableButton = false;
    });
    if (this.data.styles && this.data.styles.labelStyle) {
      if (this.data.styles.labelStyle == "hide") {
        this.showLabel = false;
      }
      // if (this.data.styles.labelStyle == "right") {
      //   this.isDefalutPos = false;
      // }
    }
  },
  // tharuni end
  methods: {
    applyFormRules() {
      this.$emit("applyFormRules");
    },
    openSignature() {
      
      // if (this.data.filled_by == "SENDER") {
      //   return;
      // }
      if (this.checkReadonly()) {
        return;
      }
      if (this.isView) {
        return;
      }
      this.signatureDialogVisible = true;
    },
    disableFieldButton() {
      return !this.data.source;
    },
    saveSign() {
      this.signatureDialogVisible = false;
      this.form[this.data.key] = this.data.source;
      this.applyFormRules();
    },

    checkReadonly() {
      //If it's an entity varaible, have to check access
      if (
        this.data.field_assignable === "read_only" &&
        this.data.is_entity_variable
      ) {
        return true;
      }
      return !this.fieldFilledByCurrentUser(this.data.filled_by);
    }
  }
};
</script>

<style lang="scss" scoped>
.avatar-uploader {
  height: inherit !important;
  justify-content: center !important;
  display: block;
}

.sign_img {
  height: inherit !important;
}
.highlighted-border .avatar-uploader {
    border: 1px dashed #ff0000;
}
</style>